<template>
    <overlay ref="overlay" @closed="$emit('closed')">
        <div>
            <api-image
                v-if="article && articleImage"
                :id="articleImage"
                :alt="$t('product_image')"
            />
            <h3 class="center">{{ $t('specifications') }}</h3>
            <div v-if="loading || saving">
                <div class="center" data-uk-spinner="ratio: 4" />
                <div v-if="saving" class="center">{{ saving }}</div>
                <div v-else class="center">{{ $t('loading.article_info') }}</div>
            </div>
            <ol v-else class="content">
                <li>
                    <div class="left">{{ $t('description') }}</div>
                    <div class="right">{{ customDescription }}</div>
                </li>
                <li>
                    <div class="left">{{ $t('factory_description') }}</div>
                    <div class="right">{{ article.descriptions.articleDescription }}</div>
                </li>
                <li>
                    <div class="left">{{ $t('article_number') }}</div>
                    <div class="right">{{ article.id }}</div>
                </li>
                <li>
                    <div class="left">{{ $t('manufacturer_code') }}</div>
                    <div class="right">{{ article.manufacturerCode }}</div>
                </li>
                <li v-if="article.manufacturerCode2">
                    <div class="left">{{ $t('manufacturer_code') }}&nbsp;2</div>
                    <div class="right">{{ article.manufacturerCode2 }}</div>
                </li>
                <li>
                    <div class="left">{{ $t('ean') }}</div>
                    <div class="right">{{ ean }}</div>
                </li>
                <li v-for="customField in customFields" :key="`cf_${customField.name}`">
                    <div class="left">{{ customField.name }}</div>
                    <div class="right">
                        <input
                            class="custom-field"
                            type="text"
                            v-model="customField.value"
                        />
                    </div>
                </li>
            </ol>
            <div class="action-button-class" v-if="unsavedCustomFields">
                <button
                    class="action-button"
                    @click="saveCustomFields()"
                >
                    <i class="fal fa-save" />
                </button>
                <button
                    v-if="enableLoading"
                    class="action-button"
                    @click="saveCustomFields(true)"
                >
                    <span class="center" style="align-items: center; gap: 10px;">
                        <i class="fal fa-save" />
                        <span style="font-size: 30px;">+ {{ $t('load') }}</span>
                    </span>
                </button>
            </div>
            <div class="action-button-class" v-else>
                <button
                    v-if="enableLoading"
                    class="action-button"
                    @click="loadLine()"
                >
                    <span class="center" style="font-size: 30px;">
                        {{ $t('load') }}
                    </span>
                </button>
            </div>
        </div>
    </overlay>
</template>

<style scoped lang="scss">
    h3 {
        margin-top: 100px !important;
    }
    input {
        outline: none;
    }
    .content {
        color: #FFFFFF;

        ol {
            margin-top: 0;

            li {
                @include center();

                border-bottom: 1px solid #FFFFFF;
                gap:           30px;
                font-size:     1.3rem;
                padding:       20px 0;
                width:         90%;

                .left {
                    width:      200px;
                    text-align: right;
                }
                .right {
                    width:      300px;
                    text-align: left;

                    .custom-field {
                        height:    100%;
                        width:     97%;
                        font-size: 20px;
                    }
                }
            }
        }
        .center {
            @include center();
            color: #fff;
        }
        .action-button-class {
            display:         flex;
            align-items:     center;
            justify-content: center;
            gap:             10px;

            .action-button {
                min-width:     75px;
                height:        75px;
                border-radius: 5px;
                border:        none;
                font-size:     40px;
                text-align:    center;
                background:    #fff;
                color:         var(--item-blue-to-darkBlue);
                margin-top:    15px;
                padding:       15px;

            }
        }
    }
</style>

<script>
import { apiClient } from '@/api';
import ApiImage      from '@/components/ApiImage';
import Overlay       from '../../../../../components/overlay/Overlay';

export default {
    name:       "ItemLineOverlay",
    components: { ApiImage, Overlay },
    props: {
        enableLoading: { type: Boolean, default: false },
        item:          { type: Object, required: true },
        line:          { type: Object, required: true },
        ride:          { type: Object, required: true },
    },
    data() {
        return {
            article:              null,
            originalCustomFields: [],
            customFields:         [],
            loading:              true,
            saving:               false,
        }
    },
    computed: {
        customDescription() {
            if (! this.article) return '';
            else if (this.article.descriptions && this.article.descriptions.customDescription) {
                return this.article.descriptions.customDescription;
            }
            else return this.article.description;
        },
        ean() {
            if (this.article && this.article.barcode) return this.article.barcode
            else                                      return '';
        },
        articleImage() {
            if (this.article && this.article.images) {
                for (const id of this.article.images) {
                    if (id !== null) return id;
                }
            }
            return null;
        },
        unsavedCustomFields() {
            return this.customFields.some((field, idx) => (field.value !== this.originalCustomFields[idx].value));
        }
    },
    async created()
    {
        try {
            const requestConfig = {
                params: {
                    id:      this.line.article,
                    include: 'descriptions',
                },
            };
            const articleResult = await apiClient.get('articles', requestConfig);
            this.article        = articleResult.data.data[0];

            this.ride.articleCustomFieldNames.forEach((fieldName) => {
                let fieldValue = '';
                if (this.article.customFields[fieldName]) fieldValue = this.article.customFields[fieldName];
                this.originalCustomFields.push({
                    name:  fieldName,
                    value: fieldValue
                });
                this.customFields.push({
                    name:  fieldName,
                    value: fieldValue
                });
            });
            this.loading = false;

        } catch (e) {
            console.error(e); //eslint-disable-line no-console
            throw e;
        } finally {
            this.loading = false;
        }
    },
    methods: {
        loadLine() {
            this.$emit('lineLoaded', this.line);
            this.$emit('closed');
        },
        async saveCustomFields(loadLine = false) {
            try {
                this.saving = this.$t('storing_article_info');
                // Just to be safe only store actually changed customFields
                let toSave = {};
                this.customFields.forEach((field, idx) => {
                    if (field.value !== this.originalCustomFields[idx].value) toSave[field.name] = field.value;
                });
                await apiClient.put(`articles/${this.article.id}`, { customFields: toSave });
                if (loadLine) {
                    this.loadLine();
                } else {
                    this.$set(this, 'originalCustomFields', this.customFields);
                    this.saving = false;
                }
            } catch (e) {
                this.saving = false;
                console.error(e); //eslint-disable-line no-console
                throw e;
            }
        },
        show() {
            this.$refs.overlay.show();
        },
    },
};
</script>

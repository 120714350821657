<template>
    <div>
        <span v-if="! id">{{ $t('no_image') }}</span>
        <i v-else-if="! src" class="icon icon-4x icon-circle-third icon-spin" />
        <img v-else :src="src" :alt="alt" />
    </div>
</template>

<script>
import { apiClient } from '@/api';

export default {
    name: "ApiImage",

    props: {
        id:  {
            type:     [ Number, null ],
            required: true
        },
        alt: {
            type:     String,
            required: true
        },
    },

    data() {
        return {
            src: null,
        };
    },

    watch: {
        id(newId) {
            if (this.src) this.freeImage();
            if (newId)    this.loadImage();
        },
    },

    created() {
        if (this.id) {
            this.loadImage();
        }
    },

    destroyed() {
        URL.revokeObjectURL(this.src);
    },

    methods: {
        async loadImage() {
            const response = await apiClient(`files/${this.id}/download`, { responseType: 'blob' });
            this.src       = URL.createObjectURL(response.data);
        },

        freeImage() {
            URL.revokeObjectURL(this.src);
            this.src = null;
        },
    },
};

</script>

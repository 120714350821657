<template>
    <li class="line">
        <a :class="value.selected ? 'checked' : ''">
            <div class="left" @click="showLineOverlay">
                <p class="amount">
                    <strong>{{ value.quantity | quantity }}</strong>
                </p>
                <p class="unit">{{ value.unitText }}</p>
            </div>
            <div class="middle" @click="showLineOverlay">
                <h4>{{ value.description }}</h4>
                <p v-if="packagingString">
                    {{ packagingString }}
                </p>
            </div>
            <div class="right">
                <div
                    v-if="updating"
                    class="spinner"
                    data-uk-spinner
                />
                <input
                    v-else-if="action === 'load'"
                    :checked="value.loadStatus > 0"
                    type="checkbox"
                    class="uk-checkbox"
                    :disabled="disableLoading"
                    @change="handleLoadStatusChange($event)"
                />
                <input
                    v-else
                    :checked="value.loadStatus === 2"
                    type="checkbox"
                    class="uk-checkbox"
                    :disabled="disableLoading"
                    @change="handleLoadStatusChange($event)"
                />
            </div>
        </a>
    </li>
</template>

<style lang="scss">
    .line {
        a {
            display:               grid;
            grid-template-columns: 100px 1fr 100px;
            gap:                   15px;
            align-items:           center;
            padding:               10px 20px;
            background-color:      var(--item-background);
            border-bottom:         1px solid var(--app-background-color);
            border-radius:         5px;

            .left {
                display: flex;

                p {
                    margin:    0;
                    font-size: 16px;
                    color:     var(--dynamic-title-color);
                }
                .amount {
                    text-align:  right;
                    width:       50px;
                    margin-left: -15px;
                }
                .unit {
                    text-align:  right;
                    width:       30px;
                    margin-left: 5px;
                }
            }
            .middle {
                text-align: left;
                word-break: break-word;

                h4,
                p {
                    margin: 0;
                    color:  var(--dynamic-title-color);
                }
                h4 {
                    font-size: 18px;
                }
                p {
                    font-size: 16px;
                }
            }
            .right {
                text-align:   right;
                margin-right: 5px;

                input {
                    width:         30px;
                    height:        30px;
                    border-radius: 5px;
                    z-index:       100;

                    i {
                        color: #fff;
                    }
                }
            }
        }
    }
    .checked {
        opacity: 0.5;
    }
    .spinner {
        margin-left: 10px;

        &::v-deep circle {
            stroke: #fff !important;
        }
    }
</style>

<script>
import createDialog    from '../../../../../support/createDialog';
import ItemLineOverlay from "@/views/transportRide/overview/ride/item/ItemLineOverlay.vue";
import {
    ITEM_STATUS,
    ITEM_TYPE, PURCHASE_RECEIPT_LINE_LOAD_STATUS,
    RIDE_STATUS
} from '@/constants';

export default {
    name:       "ItemLine",
    components: {},
    props: {
        action:   { type: String,  required: true },
        disable:  { type: Boolean, required: true },
        ride:     { type: Object,  required: true },
        item:     { type: Object,  required: true },
        updating: { type: Boolean, required: true },
        value:    { type: Object,  required: true }
    },
    computed: {
        disableLoading: function () {
            return (
                this.disable
                || this.ride.status.code === RIDE_STATUS.CONCEPT
                || this.ride.status.code === RIDE_STATUS.COMPLETED
                || this.item.type !== ITEM_TYPE.PURCHASE_RECEIPT
                || this.item.itemStatus > ITEM_STATUS.LOADED
            );
        },
        packagingString: function () {
            return '';
        },
    },
    methods: {
        handleLoadStatusChange(e) {
            const checked  = e.target.checked;
            let loadStatus = PURCHASE_RECEIPT_LINE_LOAD_STATUS.NOT_LOADED;
            if (this.action === 'load') {
                if (checked) loadStatus = PURCHASE_RECEIPT_LINE_LOAD_STATUS.LOADED;
            } else {
                if (checked) loadStatus = PURCHASE_RECEIPT_LINE_LOAD_STATUS.UNLOADED;
                else         loadStatus = PURCHASE_RECEIPT_LINE_LOAD_STATUS.LOADED;
            }
            let line = this.value;
            line.loadStatus = loadStatus;
            this.$emit('lineLoadStatusChanged', { line, item: this.item })
        },
        async showLineOverlay() {
            const dialog = await createDialog(this, ItemLineOverlay, {
                enableLoading:  ! this.disableLoading,
                item:           this.item,
                line:           this.value,
                ride:           this.ride
            });
            dialog.$on('lineLoaded', (line) => {
                line.loadStatus = PURCHASE_RECEIPT_LINE_LOAD_STATUS.LOADED;
                this.$emit('lineLoadStatusChanged', { line, item: this.item })
            });
        },
    },
};
</script>

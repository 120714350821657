<template>
    <overlay ref="overlay" @closed="$emit('closed')">
        <div>
            <api-image
                v-if="article && articleImage"
                :id="articleImage"
                :alt="$t('product_image')"
            />
            <h3 class="center">{{ $t('specifications') }}</h3>
            <div v-if="loading">
                <div class="center" data-uk-spinner="ratio: 4" />
                <div class="center">{{ $t('loading.article_info') }}</div>
            </div>
            <ol v-else class="content">
                <li>
                    <div class="left">{{ $t('description') }}</div>
                    <div class="right">{{ customDescription }}</div>
                </li>
                <li>
                    <div class="left">{{ $t('factory_description') }}</div>
                    <div class="right">{{ article.descriptions.articleDescription }}</div>
                </li>
                <li>
                    <div class="left">{{ $t('article_number') }}</div>
                    <div class="right">{{ article.id }}</div>
                </li>
                <li>
                    <div class="left">{{ $t('manufacturer_code') }}</div>
                    <div class="right">{{ article.manufacturerCode }}</div>
                </li>
                <li>
                    <div class="left">{{ $t('ean') }}</div>
                    <div class="right">{{ ean }}</div>
                </li>
            </ol>
        </div>
    </overlay>
</template>

<style scoped lang="scss">

h3 {
    margin-top: 100px !important;
}

.content {
    color: #FFFFFF;

    ol {
        margin-top: 0;

        li {
            @include center();
            border-bottom: 1px solid #FFFFFF;
            gap:           30px;
            font-size:     1.3rem;
            padding:       20px 0;
            width:         90%;

            .left {
                width:      200px;
                text-align: right;
            }

            .right {
                width:      300px;
                text-align: left;
            }
        }
    }

    .center {
        @include center();
    }
}

</style>

<script>
import { apiClient } from '@/api';
import ApiImage      from '@/components/ApiImage';
import Overlay       from '../../../../../components/overlay/Overlay';

export default {
    name:       "PackingSlipLineOverlay",
    components: { ApiImage, Overlay },
    props:      {
        line: { type: Object, required: true },
    },
    data() {
        return {
            article: null,
            loading: true,
        }
    },
    computed: {
        customDescription() {
            if (! this.article) return '';
            else if (this.article.descriptions && this.article.descriptions.customDescription) {
                return this.article.descriptions.customDescription;
            }
            else return this.article.description;
        },
        ean: function () {
            if (this.article && this.article.barcode) return this.article.barcode
            else                                      return '';
        },
        articleImage() {
            if (this.article && this.article.images) {
                for (const id of this.article.images) {
                    if (id !== null) return id;
                }
            }
            return null;
        },
    },
    async created()
    {
        try {
            const requestConfig = {
                params: {
                    id:      this.line.article,
                    include: 'descriptions',
                },
            };
            const articleResult = await apiClient.get('articles', requestConfig);
            this.article        = articleResult.data.data[0];
            this.loading        = false;

        } catch (e) {
            console.error(e); //eslint-disable-line no-console
            throw e;
        } finally {
            this.loading = false;
        }
    },
    methods: {
        show() {
            this.$refs.overlay.show();
        },
    },
};
</script>
